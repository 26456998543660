import React from 'react';
import '../styles/Policy.css'; // Import the CSS file
import policyImage from '../assets/images/slide03.jpg'; // Import your image

const Policy = () => {
  return (
    <div className="policyContainer">
      <h1 className="policyTitle">Impartiality Policy</h1>
      <img src={policyImage} alt="Impartiality Policy" className="policyImage" />
      <p className="policyText">
        Being impartial, and being perceived to be impartial, is necessary for DAS Certification group Int to deliver certification that provides confidence. The entire team of DAS Certification group Int and other persons involved in the certification activities fully understand the importance of impartiality in undertaking any certification activities.
      </p>
      <p className="policyText">
        DAS Certification group Int recognizes that the source of revenue for a certification body is the client paying for certification, and that this is a potential threat to impartiality. Therefore, DAS Certification group Int has a number of controls to ensure that impartiality is retained.
      </p>
      <p className="policyText">
        To obtain and maintain confidence, it is essential that DAS Certification group Int’s certification decisions are based on objective evidence of conformity or nonconformity, and that any decisions made are not influenced by other interests or by other parties. Certification decisions are made and signed for by a competent Scheme Manager who was not responsible for the audit and was not a member of the audit team.
      </p>
      <p className="policyText">
        When certain relations create unacceptable threats to impartiality, the Certification shall not be conducted. DAS Certification group Int shall not certify another Certification Body for its activities relating to Management System Certification.
      </p>
      <p className="policyText">
        When potential threats to impartiality arise, DAS Certification group Int eliminates or mitigates those threats. This process is monitored by the Impartiality Committee.
      </p>
      <p className="policyText">
        DAS Certification group Int does not outsource audits or any other certification activities and, where possible, DAS Certification group Int will make sure that personnel who have provided consultancy or internal audit services in the previous two years shall not take part in certification activities.
      </p>
    </div>
  );
}

export default Policy;
