import image1 from "../assets/images/Accreditations1.jpg";
import image2 from "../assets/images/Accreditations2.jpg";

const Accredation = () => {
  return (
    <div className="AccredationHeading">
      <div className="">
        <div className="">
          <div className="">
            <h2 className="text-primary text-success AccreditationsHeader">
              Our Accreditations
            </h2>
            <div className="AccreditationsImages">
              <img src={image1} alt="" />
              <img src={image2} alt="" className="AccreditationsImagesGlobal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accredation;
