import React from 'react'

import blog1 from '../assets/images/blog1.jpg'
import blog2 from '../assets/images/blog2.jpg'
import blog3 from '../assets/images/blog3.jpg'
import blog4 from '../assets/images/blog4.jpg'



const Blogs = () => {
  return (
    <>
  <section className="blog-wrapper p-5">
    <div className="container-xxl">
      <div className="row">
      <div className="col-12">
          <div className="shop-details text-center align-items-center">
            <h1 className="text-white">#Read More</h1>
            <p className='text-white fs-3'>Get to know what our trusted customers say...</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="blogs p-5">
    <div className="container-xxl container">
      <div className="row">
        <div className="d-flex flex-column align-items-center">
          <h1 className='mb-3'>Explore More </h1>
          <h3 className='mb-4'>DAS International is an independent Certification Body.</h3>
        </div>
        <div className="col-12 p-5">
          <div className="card mb-3">
          <div className="row g-0">
            <div className="col-md-4">
              <img src={blog1} className="img-fluid rounded-start" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title">ISO 9001:2015</h3>
                <p className="card-text mt-3">ISO 9001 Certified” means an organization has met the requirements in ISO 9001, which defines an ISO 9001 Quality Management System (QMS). ISO 9001 evaluates whether your Quality Management System is appropriate and effective, while forcing you to identify and implement improvements.</p>
                
              </div>
              </div>
           
             </div>
          </div>
        </div>
        <div className="col-12 p-5">
          <div className="card mb-3">
          <div className="row g-0">
            <div className="col-md-4">
              <img src={blog2} className="img-fluid rounded-start" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title">ISO 14001:2015</h3>
                <p className="card-text mt-3">ISO 14001:2015 specifies the requirements for an environmental management system that an organization can use to enhance its environmental performance. ISO 14001:2015 is intended for use by an organization seeking to manage its environmental responsibilities in a systematic manner that contributes to the environmental pillar of sustainability.</p>
                
              </div>
              </div>
           
             </div>
          </div>
        </div>
        <div className="col-12 p-5">
          <div className="card mb-3">
          <div className="row g-0">
            <div className="col-md-4">
              <img src={blog3} className="img-fluid rounded-start" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title">ISO 22000:2018</h3>
                <p className="card-text mt-3">ISO 22000 sets out the requirements for a food safety management system and can be certified to it. It maps out what an organization needs to do to demonstrate its ability to control food safety hazards in order to ensure that food is safe.</p>
               
              </div>
              </div>
           
             </div>
          </div>
        </div>
        <div className="col-12 p-5">
          <div className="card mb-3">
          <div className="row g-0">
            <div className="col-md-4">
              <img src={blog4} className="img-fluid rounded-start" alt="..." />
            </div>
            <div className="col-md-6">
              <div className="card-body">
                <h3 className="card-title">ISO 45001:2018</h3>
                <p className="card-text mt-3">ISO 45001:2018 specifies requirements for an occupational health and safety (OH&S) management system, and gives guidance for its use, to enable organizations to provide safe and healthy workplaces by preventing work-related injury and ill health, as well as by proactively improving its OH&S performance.</p>
               
              </div>
              </div>
           
             </div>
          </div>
        </div>
      
       

          

            
          
      </div>
    </div>
  </section>
  </>
  )
}

export default Blogs




