import React, { useState } from 'react';
import "../styles/CertificateSearch.css";

const CertificateSearch = () => {
  const [certificateNumber, setCertificateNumber] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [hasSearched, setHasSearched] = useState(false); // New state variable

  const demoData = {
    "BD-10001-OHSMS": {
      certificateNo: "BD-10001-OHSMS",
      companyName: "XYZ PRIVATE LTD",
      standard: "ISO 45001:2018",
      accreditation: "ASiB-UK",
      scope: "Manufacturing, sales and marketing of cosmetics and herbal products",
      initialCertDate: "16-October-2024",
      issueDate: "16-October-2024",
      expiryDate: "15-October-2027",
      status: "Active"
    },
    "BD-10001-EMS": {
      certificateNo: "BD-10001-EMS",
      companyName: "XYZ PRIVATE LTD",
      standard: "ISO 14001:2015",
      accreditation: "ASiB-UK",
      scope: "Manufacturing, sales and marketing of ordinary portland cement",
      initialCertDate: "16-October-2024",
      issueDate: "16-October-2024",
      expiryDate: "15-October-2027",
      status: "Active"
    },
    "BD-10001-QMS": {
      certificateNo: "BD-10001-QMS",
      companyName: "XYZ PRIVATE LTD",
      standard: "ISO 9001:2015",
      accreditation: "ASiB-UK",
      scope: "Manufacturing, sales and marketing of ordinary portland cement",
      initialCertDate: "16-October-2024",
      issueDate: "16-October-2024",
      expiryDate: "15-October-2027",
      status: "Active"
    }
  };

  const handleSearch = () => {
    setHasSearched(true); // Set search flag when search button is clicked
    const result = demoData[certificateNumber];
    setSearchResult(result || null);
  };

  const handleInputChange = (e) => {
    setCertificateNumber(e.target.value);
    setHasSearched(false); // Reset search flag when input changes
  };

  return (
    <div className="TopMeanVerificationHeading">
      <div className="meanVerificationHeading">
        <div className="heading">
          <h2 className="searchHeading">Certificate Search</h2>
          <p className="searchPara">
            You may verify all management system certificates and trainee
            certificates which are issued by <span className="searchHeadingSpan">DAS Certification Group</span>. Please enter
            your certificate number to search.
          </p>
          <div className="searchInputFil">
            <p className="searchInputFilHeading">CERTIFICATE NUMBER:</p>
            <input 
              type="text" 
              className="searchInput" 
              value={certificateNumber}
              onChange={handleInputChange}
              placeholder="Enter certificate number (e.g., BD-10001-OHSMS)"
            />
            <div className="mainsearchInputButton">
              <button className="searchInputButton" onClick={handleSearch}>Search</button>
            </div>
          </div>
        </div>
        
        <div className="heading-bottom">
          <h2 className="heading-bottom-result">SEARCH RESULT</h2>
          {searchResult && (
            <div className="result-container">
              <div className="result-field">
                <span className="field-label">Certificate Registration No:</span>
                <span className="field-value">{searchResult.certificateNo}</span>
              </div>
              <div className="result-field">
                <span className="field-label">Company Name:</span>
                <span className="field-value">{searchResult.companyName}</span>
              </div>
              <div className="result-field">
                <span className="field-label">Standard:</span>
                <span className="field-value">{searchResult.standard}</span>
              </div>
              <div className="result-field">
                <span className="field-label">Accreditation:</span>
                <span className="field-value">{searchResult.accreditation}</span>
              </div>
              <div className="result-field">
                <span className="field-label">Scope:</span>
                <span className="field-value">{searchResult.scope}</span>
              </div>
              <div className="result-field">
                <span className="field-label">Initial Certification Date:</span>
                <span className="field-value">{searchResult.initialCertDate}</span>
              </div>
              <div className="result-field">
                <span className="field-label">Issue Date:</span>
                <span className="field-value">{searchResult.issueDate}</span>
              </div>
              <div className="result-field">
                <span className="field-label">Expiration Date:</span>
                <span className="field-value">{searchResult.expiryDate}</span>
              </div>
              <div className="result-field">
                <span className="field-label">Certificate Status:</span>
                <span className="field-value">{searchResult.status}</span>
              </div>
            </div>
          )}
          {hasSearched && !searchResult && (
            <div className="no-result">
              No certificate found with number: {certificateNumber}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CertificateSearch;