import React from 'react'
import { Container } from 'react-bootstrap'
import aboutImg from '../assets/images/About us.jpg'


const About = () => {
  return (
    <>
      <section className='AboutContainer'>
        <Container className='mt-5'>
        <div className="about-text">
          <h2 className='mb-4 text-success text-center '>ABOUT US</h2>
        
        </div>
          <div className="row gx-5 aboutContainer">
            <div className="col-lg-6 col-12">
              <div className="about-image mt-4">
                <img src={aboutImg} alt="about-image" className='rounded d-block img-fluid' />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="body-text">
                <p >
                  At DAS Certification Group, we are dedicated to providing our clients international accredited certification with the highest quality of auditing services globally. Since 2003, our team of suitable qualified auditors with their expertise and knowledge,provide added value to the industry and are ideally placed to give your audit the value it deserves.</p>

                 <p> With DAS Certification Group, you can expect a smooth and efficient auditing process that delivers the results you need to maintain the highest standards of quality and compliance. Our independent services add significant value to our customers’ operations and ensure business sustainability.</p>

                 <p>DAS Certification Group a wider range of certifications at a very competitive rate, while maintaining our high level of customer service and professionalism. We can help you gain the necessary certification you need to trade in your target markets, giving you, and your customers, the assurance you need  anywhere in the world.</p>

              
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default About