
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import certificate from '../assets/images/certification.jpg'

const Verification = () => {

  
    return (
      <Container>
        <Row className="mt-5 g-5">
          
            <h1 className='text-success mt-3 text-center mb-3'>Certificate Verification</h1>
            <Col md={6} sm={1}>
          
            <div className="certificate-text mt-4 ">
                  <p >
                  The following information will be shown for all valid DAS Certificates: Certificate Registration No, Company Name, Standard, Accreditation, Scope, Original Certification Date, Current Certification Date, Certificate Expiry Date and Certificate Status. 
                  </p>
                  <p >
                  
Should the details on the Certificate do not show up or the details on the screen do not match the ones shown on your Certificate, please report immediately to DAS Certification by sending an email to info@dascertificationgroup.com

                  </p>

                <Form >
              
              <Link to={`/CertificateSearch`}>
              <Button className="mt-2" variant="primary">Verify Certificate</Button>
            </Link>
            </Form>
                </div>
          </Col>
          <Col md={6} sm={1}>
                <div className="certificate-image">
                  <img src={certificate} alt="certificate" className='d-block w-100 rounded-1' />
                </div>
                
              </Col>
        </Row>
      </Container>
    );
  };
  


export default Verification