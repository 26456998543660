// import iso9001 from "../assets/ImageT/iso1.jpg";
// import iso14001 from "../assets/ImageT/iso2.jpg";
// import iso45001 from "../assets/ImageT/iso3.jpg";
// import iso22000 from "../assets/ImageT/iso4.jpg";
// import iso27001 from "../assets/ImageT/iso5.jpg";

import Services from "./Services";

// const trainingData = [
//   {
//     image: iso9001,
//     title: "ISO 9001:2015",
//     text: "Enhance your organization's quality management with ISO 9001 training—master the standards that drive continuous improvement. Unlock the path to excellence with ISO 9001 training—empower your team to deliver consistent quality and customer satisfaction.",
//   },
//   {
//     image: iso14001,
//     title: "ISO 14001:2015",
//     text: "Enhance your organization's environmental management with ISO 14001 training—gain expertise in sustainable practices and environmental responsibility. Achieve your sustainability goals with ISO 14001 training.",
//   },
//   {
//     image: iso45001,
//     title: "ISO 45001:2018",
//     text: "Ensure a safer workplace with ISO 45001 training—learn how to manage occupational health and safety risks effectively. Build a safer and healthier environment with ISO 45001 training.",
//   },
//   {
//     image: iso22000,
//     title: "ISO 22000:2018",
//     text: "Strengthen your food safety management system with ISO 22000 training—learn how to ensure the safety of food supply chains from production to consumption.",
//   },
//   {
//     image: iso27001,
//     title: "ISO 27001:2022",
//     text: "Protect your organization's information with ISO 27001 training—gain expertise in information security management and safeguard data integrity.",
//   },
// ];

// const Training = () => {
//   return (
//     <div className="TrainingHeading">
//       <div className="container my-5">
//         <div className="row">
//           {trainingData.map((training, index) => (
//             <div className="col-md-4 mb-4" key={index}>
//               <div className="card h-100">
//                 <img
//                   src={training.image}
//                   className="card-img-top"
//                   alt={training.title}
//                 />
//                 <div className="card-body">
//                   <h5 className="card-title text-center">{training.title}</h5>
//                   <p className="card-text">{training.text}</p>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Training;



const Training = () => {
    return (
        <div>
           <Services/>
        </div>
    );
};

export default Training;