import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import "../../styles/contact.css";

// import './contact.css'; // Import the custom CSS file
import contactImg from "../../assets/images/contact.jpg";
import contactImg2 from "../../assets/images/cotact2.jpg";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Message sent successfully!");
    setFormData({
      name: "",
      email: "",
      country: "",
      message: "",
    });
  };

  return (
    <div className="container mt-5">
      {/* Image Banner */}
      <div className="contact-banner">
        <img src={contactImg} alt="Contact Us" className="img-fluid w-100" />
        <div className="banner-overlay">
          <h1 className="text-center text-white">Contact Us</h1>
        </div>
      </div>

      {/* Contact Information Row */}
      <div className="row mb-4 pt-3 mt-4">
        <div className="col-md-3 mb-3">
          <div className="hover-border card shadow-sm h-100 p-3">
            <div className="card-body">
              <h5 className="card-title">Corporate Office (HQ)</h5>
              <p className="card-text">
                U-6, 135 Crow Lane, Romford
                <br />
                RM7 OES, United Kingdom
              </p>
              <p>
                Email:{" "}
                <a href="mailto:info@dascertificationgroup.com">
                  info@dascertificationgroup.com
                </a>
              </p>
              <p>Call: +44 7448 078182</p>
            </div>
          </div>
        </div>

        <div className="col-md-3 mb-3 ">
          <div className="hover-border card shadow-sm h-100 ">
            <div className="card-body">
              <h5 className="card-title">Global Operation</h5>
              <p className="card-text">
                H-1828, W-11, Chalklokman, Bog-5800, BD
              </p>
              <p>
                Email:{" "}
                <a href="mailto:info@dascertificationgroup.com">
                  info@dascertificationgroup.com
                </a>
              </p>
              <p>Call: +88 02 588 813 570, +88 01715110605</p>
            </div>
          </div>
        </div>

        <div className="col-md-3 mb-3">
          <div className="hover-border card shadow-sm h-100 p-3">
            <div className="card-body">
              <h5 className="card-title">USA Office</h5>
              <p className="card-text">Md Golam Azam</p>
              <p className="card-text">
                2E 7th Street, Unit 301 , Wilmington, Delaware 19801, USA
              </p>
              <p>
                Email:{" "}
                <a href="mailto:info@dascertificationgroup.com">
                  info@dascertificationgroup.com
                </a>
              </p>
              <p>Call: (+88) 02 588 813 570, (+88) 01715110605</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="hover-border card shadow-sm h-100 p-3">
            <div className="card-body">
              <h5 className="card-title">Canada </h5>
              <p className="card-text">Mr. Sazedur Rahman</p>
              <p className="card-text">
                House # 3430 Clara Dr, City: Mississauga
                Postal Code: L4T 2C7 ON Canada
              </p>
              <p>
                Email:{" "}
                <a href="mailto:info@dascertificationgroup.com">
                  info@dascertificationgroup.com
                </a>
              </p>
              <p>00 1 647-773-2010</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <div className="hover-border card shadow-sm h-100 p-3">
            <div className="card-body">
              <h5 className="card-title">Australia </h5>
              <p className="card-text">Moinur Rahman</p>
              <p className="card-text">
                938 Punchbowl Road, Punchbowl, NSW 2196 Australia
              </p>
              <p>
                Email:{" "}
                <a href="mailto:info@dascertificationgroup.com">
                  info@dascertificationgroup.com
                </a>
              </p>
              <p>+61 411559060 </p>
            </div>
          </div>
        </div>

        <div className="col-md-3 mb-3">
          <div className="hover-border card shadow-sm h-100 p-3">
            <div className="card-body">
              <h5 className="card-title">KSA</h5>
              <p className="card-text">
                Mr. Mofizur Rahman
                <br />
                3380-3358 Ibn Az Zahri, Umm Al Hamam Al Gharbi, Riyadh 12328,
                Saudi Arabia
              </p>
              <p>
                Email:{" "}
                <a href="mailto:info@dascertificationgroup.com">
                  info@dascertificationgroup.com
                </a>
              </p>
              <p>Call: +966 55 646 4860</p>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Form Section */}

      <div className="row">
        {/* Image Section */}
        <div className="col-lg-5 col-md-12 mb-4">
          <div className="contact-image-wrapper">
            <img
              src={contactImg2}
              alt="Contact"
              className="img-fluid rounded shadow h-100"
            />
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="col-lg-7 col-md-12">
          <div className="contact-form-wrapper h-100">
            <div className="card shadow-sm   p-3 contact-form">
              <div className="card-body">
                <h5 className="card-title mb-3">Get in Touch</h5>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="country"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      rows="5"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg send-button"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
