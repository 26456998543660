import React from 'react'
import Banner from '../../Components/Banner'
import Choose from '../../Components/Choose'
// import Services from '../../Components/Services'

import Verification from '../../Components/Verification'
import Footer from '../common/Footer'
import About from '../../Components/About'


const Home = () => {
  return (
   <>
    <main>
        <Banner/>
        <Choose/>
        <About/>
        {/* <Services/> */}
        
         <Verification/>
         {/* <Contact/> */}
         <Footer/>
    </main>
   </>
  )
}

export default Home