import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/images/logo2.png";
import { Link, NavLink } from "react-router-dom";
import Head from "./Head";

const Header = () => {
  return (
    <>
      <Head />
      <Navbar bg="light" expand="lg">
        <Container className="headerMainNav">
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt="" className="headerImage"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav ">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/#home" className="MainNavbar">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="about" className="MainNavbar">
                About
              </Nav.Link>
              {/* <Nav.Link as={Link} to="services" className="MainNavbar">
                Services
              </Nav.Link> */}
              <Nav.Link as={Link} to="Training" className="MainNavbar">
                Services
              </Nav.Link>
              <Nav.Link as={Link} to="Accredation" className="MainNavbar">
                Accredation
              </Nav.Link>
              {/* <Nav.Link as={Link} to="blog" className="MainNavbar">
                Blogs
              </Nav.Link> */}
              <Nav.Link as={Link} to="policy" className="MainNavbar">
                Policy
              </Nav.Link>
              <Nav.Link as={Link} to="contact" className="MainNavbar">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
