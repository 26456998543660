import React from "react";
// import logo2 from "../../assets/images/certificate.jpg";
import FooterLogo from "../../assets/images/logo2.png";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container padding">
          <div className="box logo">
            <img src={FooterLogo} className="footerLogoMain" alt="" />
            <p>Sustainability through</p>
            <p>Continual Improvement</p>

            <i className="fab fa-facebook-f icon"></i>
            <i className="fab fa-twitter icon"></i>
            <i className="fab fa-instagram icon"></i>
          </div>
          <div className="box link ms-4">
            <h3>Explore</h3>
            <ul>
              <li>About </li>
              <li>Services</li>
              <li>Contact</li>
              <li>Blog</li>
              <li>Verification</li>
            </ul>
          </div>
          <div className="box link ms-3">
            <h3>Quick Links</h3>
            <ul>
              <li>Contact Us</li>
              <li>Pricing</li>
              <li>Policy</li>
              <li>Privacy</li>
              <li>Feedbacks</li>
            </ul>
          </div>

          <div className="box last ms-5 ">
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <i className="fa fa-map"></i>
                U-6, 135 Crow Lane, Romford RM7 OES, United Kingdom
              </li>
              <li className="mb-2">
                <i className="fa fa-phone-alt"></i>
                Call: (+88) 02 588 813 570, (+88) 01 715 110 605
              </li>
              <li>
                <i className="fa fa-paper-plane"></i>
                info@dascertificationgroup.com{" "}
              </li>
            </ul>
          </div>
          <div>
            {/* <div className="brand-image">
              <img src={logo2} alt="" />
            </div> */}
          </div>
        </div>
      </footer>
      <div className="legal">
        <p className="text-center">
          Copyright © 2024 All rights reserved - DAS Certification Group
        </p>
      </div>
    </>
  );
};

export default Footer;
