// import React from "react";
// import { Link } from "react-router-dom";
// import logo from "../assets/images/logo2.png";

// const pdfFile =
//   "http://localhost:3000/FMP19_7a45 Multistandard Application DAS.docx";
// const Head = () => {
//   const downloadFileUrl = (url) => {
//     fetch(url)
//       .then((res) => res.blob())
//       .then((blob) => {
//         const blobURL = window.URL.createObjectURL(new Blob([blob]));
//         const fileName = url.split("/").pop();
//         const aTag = document.createElement("a");
//         aTag.href = blobURL;
//         aTag.setAttribute("download", fileName);
//         document.body.appendChild(aTag);
//         aTag.click();
//         aTag.remove();
//       });
//   };

//   return (
//     <>
//       <section className="head">
//         <div className="container flexSB top-nav">
//           <div>
//             <Link to="/">
//               <span className="ps-3">
//                 <img className="HeaderLogo" src={logo} alt="" />
//               </span>
//             </Link>
//           </div>
//           <div className="social d-flex gap-4">
//             {/* <button  className="btn btn-primary" onClick={()=>{downloadFileUrl(pdfFile)}}>Get Qoute <i className="fas fa-chevron-right"></i></button> */}
//             <div className="headAddress">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke-width="1.5"
//                 stroke="currentColor"
//                 class="size-6"
//                 width={20}
//                 color="red"
//                 className="headAdressIcon"
//               >
//                 <path
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                   d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
//                 />
//                 <path
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                   d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
//                 />
//               </svg>

//               <p className="heading-address">
//                 H-1828, W-11, Chalklokman, Bog-5800, BD U-6, 135 Crow Lane,
//                 Romford RM7 OES, United Kingdom
//               </p>
//             </div>
//             {/* <Link to="CertificateSearch">
//               <a className="btn btn-primary">
//                 Verification <i className="fas fa-chevron-right"></i>
//               </a>
//             </Link> */}
//             <div className="headAddress">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke-width="1.5"
//                 stroke="currentColor"
//                 class="size-6"
//                 width={20}
//                 color="red"
//                 className="headAdressIcon"
//               >
//                 <path
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                   d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
//                 />
//               </svg>

//               <p className="heading-address">
//                 Email:{" "}
//                 <a href="mailto:info@dascertificationgroup.com">
//                   info@dascertificationgroup.com
//                 </a>
//                 <p>Call: (+88)02588813570, (+88) 01715110605</p>
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Head;

import React from "react";
import { Link } from "react-router-dom";

const pdfFile =
  "http://localhost:3000/FMP19_7a45 Multistandard Application QAS.docx";
const Head = () => {
  const downloadFileUrl = (url) => {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  return (
    <>
      <section className="head">
        <div className="container flexSB top-nav">
          <div>
            <span className="ps-3">DAS Certification Group</span>
          </div>

          <div className="email">
            <span className="ps-4">info@dascertificationgroup.com</span>
          </div>

          <div className="social d-flex gap-4">
            <button
              className="btn btn-primary"
              // onClick={() => {
              // 	downloadFileUrl(pdfFile);
              // }}
            >
              Get Qoute
            </button>
            <Link to='CertificateSearch' className="btn btn-primary">Verification</Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
